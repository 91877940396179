

















import { notFound } from '~/utils/page'
import { loadBlockData } from '~/utils/has-blocks'
import blocks from '~/components/global/blocks'
import layoutFooter from '~/components/layout/footer'
import pageTransition from '~/utils/page-transition-mixin'
import structuredData from '~/components/global/structured-data'
export default
	name: 'Tower'

	mixins: [ pageTransition ]

	components: {
		blocks
		layoutFooter
		structuredData
	}

	# Title / SEO
	head: ->
		title: (title = (@page.seoTitle or @page.title))
		meta: [
			@$metaTag 'og:title', title
			@$metaTag 'description', @page.seoDescription
			@$metaTag 'og:image', do =>
				if @page.seoImage
					"https:#{@$contentful.img(@page.seoImage, 1200, 1200)}"
			@$metaTag 'robots', @page.seoRobots?.join ', '
		]
		.concat @twitterLabels
		.filter (tag) -> !!tag?.content

	# Get page data and blocks
	asyncData: (context) ->
		{ error, app, route, store } = context

		# Lookup the tower
		page = await app.$contentful.getEntry 'tower',
			'fields.route': route.path
			include: 1
		return error notFound unless page

		# Hydrate some blocks with additional data so that it gets SSRed
		await loadBlockData context, page.blocks

		# Save the page
		{ page }

	# Hack in a simple scroll to team page.  This was moved out of the people
	# block because it may not be mounted yet on the iniital render because of
	# lazy-hydrate.
	mounted: ->
		if @$route.fullPath == '/studio#team'

			# Looks/works best with a delay.  This was originallly 250 but on touch
			# devices there was some sorta race condition.
			@$wait 750, =>
				if peopleEl = document.querySelector '.people-block'

					# Note, if someone deep links here, which is rare IMO, the
					# positioing is a bit off because of lazy-hydrate.  Ideally I disable
					# the deep linking behavior and only execute this when part of the
					# close buttons from the person detail.
					@$scrollbar.scrollTo peopleEl.getBoundingClientRect().top - 20

	computed:

		# Make twirter labels, for nicer Slack sharing
		twitterLabels: -> switch @$route.path
			when '/' then [
				@$metaTag 'twitter:label1', 'See our work'
				@$metaTag 'twitter:data1', 'https://www.bukwild.com/work'
				@$metaTag 'twitter:label2', 'Contact us'
				@$metaTag 'twitter:data2', 'https://www.bukwild.com/contact'
			]
			else []

